<!--
  Component to display a selected user in the admin screen
-->
<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ 'Edit User Access' }}</p>
    </header>
    <section class="modal-card-body">
      <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
      <strong>
        <p>{{ $t(`Change user's module access options below`) }}</p>
      </strong>
      <br />

      <p
        class="has-text-weight-semibold has-text-primary is-size-5"
        style="margin-bottom: 1em"
      >
        {{ email }}
      </p>

      <div class="flex-parent">
        <b-field v-if="adminAccess || superUser" :label="$t('Super User')">
          <b-switch v-model="superUserLocal"></b-switch>
        </b-field>
        <b-field v-if="purchasedDataLoaders" :label="$t('Data Managers')">
          <b-switch v-model="dataLoadersLocal"></b-switch>
        </b-field>
        <b-field
          v-if="purchasedActivityMigrator"
          :label="$t('Activity Migrator')"
        >
          <b-switch v-model="activityMigratorLocal"></b-switch>
        </b-field>
        <b-field v-if="purchasedIsochrone" :label="$t('Coverage Analytics')">
          <b-switch v-model="isochroneLocal"></b-switch>
        </b-field>
        <b-field v-if="purchasedOmw" :label="$t('OMW Viewer')">
          <b-switch v-model="omwLookupLocal"></b-switch>
        </b-field>
        <b-field v-if="purchasedOmw" :label="$t('OMW Stats')">
          <b-switch v-model="omwStatsLocal"></b-switch>
        </b-field>
        <b-field v-if="purchasedOmw" :label="$t('OMW Kill Switch')">
          <b-switch v-model="omwKillSwitchLocal"></b-switch>
        </b-field>
        <b-field v-if="purchasedFeedback" :label="$t('Cust. Feedback')">
          <b-switch v-model="feedbackLocal"></b-switch>
        </b-field>
        <b-field v-if="purchasedRealTimeTravel" :label="$t('Real-Time Travel')">
          <b-switch v-model="realTimeTravelLocal"></b-switch>
        </b-field>
        <b-field v-if="purchasedMarketing" :label="$t('OMW Marketing')">
          <b-switch v-model="marketingLocal"></b-switch>
        </b-field>
        <b-field v-if="purchasedPdflify" :label="$t('PDFlify')">
          <b-switch v-model="pdflifyLocal"></b-switch>
        </b-field>
        <b-field
          v-if="purchasedInventoryManager"
          :label="$t('Inventory Manager')"
        >
          <b-switch v-model="inventoryManagerLocal"></b-switch>
        </b-field>
        <b-field v-if="customerAdminAccess" :label="$t('Administrator')">
          <b-switch v-model="customerAdministratorLocal"></b-switch>
        </b-field>
      </div>

      <div class="has-text-centered buttons">
        <b-button type="is-primary" @click="processUserUpdate">
          {{ $t('Update') }}
        </b-button>

        <b-button
          v-if="customerAdminAccess || leadentAccess"
          type="is-danger"
          @click="processUserDeletion"
        >
          {{ $t('Delete') }}
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import { defineComponent } from '@vue/composition-api';

import {
  deleteUserInFirebase,
  updateUserInFirebase,
} from '@/services/users/index';

export default defineComponent({
  name: 'SelectedUser',
  props: {
    email: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    dataLoaders: {
      type: Boolean,
      required: true,
      default: false,
    },
    omwLookup: {
      type: Boolean,
      required: true,
      default: false,
    },
    omwStats: {
      type: Boolean,
      required: true,
      default: false,
    },
    feedback: {
      type: Boolean,
      required: true,
      default: false,
    },
    superUser: {
      type: Boolean,
      required: true,
      default: false,
    },
    activityMigrator: {
      type: Boolean,
      required: true,
      default: false,
    },
    isochrone: {
      type: Boolean,
      required: true,
      default: false,
    },
    realTimeTravel: {
      type: Boolean,
      required: true,
      default: false,
    },
    marketing: {
      type: Boolean,
      required: true,
      default: false,
    },
    pdflify: {
      type: Boolean,
      required: true,
      default: false,
    },
    inventoryManager: {
      type: Boolean,
      required: true,
      default: false,
    },
    omwKillSwitch: {
      type: Boolean,
      required: true,
      default: false,
    },
    customerAdministrator: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      omwLookupLocal: null,
      feedbackLocal: null,
      omwStatsLocal: null,
      dataLoadersLocal: null,
      superUserLocal: null,
      activityMigratorLocal: null,
      isochroneLocal: null,
      realTimeTravelLocal: null,
      marketingLocal: null,
      pdflifyLocal: null,
      inventoryManagerLocal: null,
      omwKillSwitchLocal: null,
      customerAdministratorLocal: null,
    };
  },
  watch: {
    omwLookup(newValue) {
      this.omwLookupLocal = newValue;
    },
    omwStats(newValue) {
      this.omwStatsLocal = newValue;
    },
    feedback(newValue) {
      this.feedbackLocal = newValue;
    },
    dataLoaders(newValue) {
      this.dataLoadersLocal = newValue;
    },
    superUser(newValue) {
      this.superUserLocal = newValue;
    },
    activityMigrator(newValue) {
      this.activityMigratorLocal = newValue;
    },
    isochrone(newValue) {
      this.isochroneLocal = newValue;
    },
    realTimeTravel(newValue) {
      this.realTimeTravelLocal = newValue;
    },
    marketing(newValue) {
      this.marketingLocal = newValue;
    },
    pdflify(newValue) {
      this.pdflifyLocal = newValue;
    },
    inventoryManager(newValue) {
      this.inventoryManagerLocal = newValue;
    },
    customerAdministrator(newValue) {
      this.customerAdministratorLocal = newValue;
    },
    omwKillSwitch(newValue) {
      this.omwKillSwitchLocal = newValue;
    },
  },
  created() {
    this.omwLookupLocal = this.omwLookup;
    this.omwStatsLocal = this.omwStats;
    this.dataLoadersLocal = this.dataLoaders;
    this.feedbackLocal = this.feedback;
    this.superUserLocal = this.superUser || false;
    this.activityMigratorLocal = this.activityMigrator;
    this.isochroneLocal = this.isochrone;
    this.realTimeTravelLocal = this.realTimeTravel;
    this.marketingLocal = this.marketing;
    this.pdflifyLocal = this.pdflify;
    this.inventoryManagerLocal = this.inventoryManager;
    this.customerAdministratorLocal = this.customerAdministrator;
    this.omwKillSwitchLocal = this.omwKillSwitch;
  },
  methods: {
    async processUserDeletion() {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: 'Warning',
        hasIcon: true,
        icon: 'exclamation',
        confirmText: 'Delete User',
        message: `Are you sure you wish to delete user: ${this.email}?`,
        onConfirm: async () => {
          this.isLoading = true;
          const user = {
            id: this.id,
          };

          // Delete the firebase database entries
          try {
            await deleteUserInFirebase(user);
          } catch (err) {
            console.error(err);
            this.$buefy.toast.open({
              duration: 10000,
              message: `Error deleting user: ${err}`,
              type: 'is-danger',
            });
            return;
          } finally {
            this.isLoading = false;
          }
          // Call to backend to delete the firebase auth entry
          try {
            const options = {
              method: 'DELETE',
              headers: {
                Authorization: `Bearer ${this.idToken}`,
              },
              url: `${this.$configData.auth.delete.url}/${this.id}`,
            };
            await axios(options);
            this.$emit('user-deleted', this.id);
            this.$parent.close();
            this.$buefy.toast.open({
              duration: 3000,
              message: 'User deleted successfully',
              type: 'is-success',
            });
          } catch (err) {
            console.error(err);
            this.$buefy.toast.open({
              duration: 10000,
              message: `Error deleting user: ${err}`,
              type: 'is-danger',
            });
          } finally {
            this.isLoading = false;
          }
        },
      });
    },
    processUserUpdate() {
      const user = {
        id: this.id,
        email: this.email,
        omwLookup: this.omwLookupLocal,
        omwStats: this.omwStatsLocal,
        dataLoaders: this.dataLoadersLocal,
        feedback: this.feedbackLocal,
        superUser: this.superUserLocal,
        activityMigrator: this.activityMigratorLocal,
        isochrone: this.isochroneLocal,
        realTimeTravel: this.realTimeTravelLocal,
        marketing: this.marketingLocal,
        pdflify: this.pdflifyLocal,
        inventoryManager: this.inventoryManagerLocal,
        customerAdministrator: this.customerAdministratorLocal,
        omwKillSwitch: this.omwKillSwitchLocal,
      };
      updateUserInFirebase(user);
      this.$emit('user-updated', user);
      this.$parent.close();
    },
  },
});
</script>

<style>
.flex-parent {
  width: 40vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
}
</style>
