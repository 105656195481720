<!--
  Component for the administration of user access to functionality
-->
<template>
  <div class="tile" style="padding-left: 10px">
    <b-loading :is-full-page="true" :active.sync="dataLoading"></b-loading>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <div class="tile">
          <div class="tile is-child">
            <b-field :label="$t('Search')">
              <b-input
                v-model="searchCriteria"
                :expanded="false"
                size="is-medium"
                type="search"
                icon="search"
                @input="search()"
              ></b-input>
            </b-field>
            <div>
              <p>
                {{ $t('Number of users') }}:
                <span class="has-text-weight-semibold">{{ users.length }}</span>
              </p>
              &nbsp;
            </div>
            <b-table
              :narrowed="true"
              :hoverable="true"
              :data="dataSet"
              :paginated="true"
              :per-page="20"
              :pagination-simple="false"
              :selected.sync="selectedRow"
              default-sort="email"
              @click="showEditUser"
            >
              <b-table-column
                v-slot="props"
                field="email"
                sortable
                :label="$t('Email')"
                >{{ props.row.email }}</b-table-column
              >
              <b-table-column
                v-slot="props"
                field="leadent"
                sortable
                centered
                :label="$t('Super User')"
              >
                <b-icon
                  size="is-small"
                  :icon="getIcon(props.row.superUser)"
                  :type="getIconColor(props.row.superUser)"
                ></b-icon>
              </b-table-column>
              <b-table-column
                v-if="purchasedActivityMigrator"
                v-slot="props"
                field="activityMigrator"
                sortable
                centered
                :label="$t('Activity Migrator')"
              >
                <b-icon
                  size="is-small"
                  :icon="getIcon(props.row.activityMigrator)"
                  :type="getIconColor(props.row.activityMigrator)"
                ></b-icon>
              </b-table-column>
              <b-table-column
                v-if="purchasedDataLoaders"
                v-slot="props"
                field="dataLoaders"
                sortable
                centered
                :label="$t('Data Managers')"
              >
                <b-icon
                  size="is-small"
                  :icon="getIcon(props.row.dataLoaders)"
                  :type="getIconColor(props.row.dataLoaders)"
                ></b-icon>
              </b-table-column>
              <b-table-column
                v-if="purchasedIsochrone"
                v-slot="props"
                field="isochrone"
                sortable
                centered
                :label="$t('Coverage Analytics')"
              >
                <b-icon
                  size="is-small"
                  :icon="getIcon(props.row.isochrone)"
                  :type="getIconColor(props.row.isochrone)"
                ></b-icon>
              </b-table-column>
              <b-table-column
                v-if="purchasedOmw"
                v-slot="props"
                field="omwLookup"
                sortable
                centered
                :label="$t('OMW Viewer')"
              >
                <b-icon
                  size="is-small"
                  :icon="getIcon(props.row.omwLookup)"
                  :type="getIconColor(props.row.omwLookup)"
                ></b-icon>
              </b-table-column>

              <b-table-column
                v-if="purchasedOmwStats"
                v-slot="props"
                field="omwStats"
                sortable
                centered
                :label="$t('OMW Stats')"
              >
                <b-icon
                  size="is-small"
                  :icon="getIcon(props.row.omwStats)"
                  :type="getIconColor(props.row.omwStats)"
                ></b-icon>
              </b-table-column>

              <b-table-column
                v-if="purchasedOmw"
                v-slot="props"
                field="omwKillSwitch"
                sortable
                centered
                :label="$t('OMW Kill Switch')"
              >
                <b-icon
                  size="is-small"
                  :icon="getIcon(props.row.omwKillSwitch)"
                  :type="getIconColor(props.row.omwKillSwitch)"
                ></b-icon>
              </b-table-column>

              <b-table-column
                v-if="purchasedFeedback"
                v-slot="props"
                field="feedback"
                sortable
                centered
                :label="$t('Cust. Feedback')"
              >
                <b-icon
                  size="is-small"
                  :icon="getIcon(props.row.feedback)"
                  :type="getIconColor(props.row.feedback)"
                ></b-icon>
              </b-table-column>
              <b-table-column
                v-if="purchasedRealTimeTravel"
                v-slot="props"
                field="realTimeTravel"
                sortable
                centered
                :label="$t('Real-Time Travel')"
              >
                <b-icon
                  size="is-small"
                  :icon="getIcon(props.row.realTimeTravel)"
                  :type="getIconColor(props.row.realTimeTravel)"
                ></b-icon>
              </b-table-column>
              <b-table-column
                v-if="purchasedMarketing"
                v-slot="props"
                field="marketing"
                sortable
                centered
                :label="$t('OMW Marketing')"
              >
                <b-icon
                  size="is-small"
                  :icon="getIcon(props.row.marketing)"
                  :type="getIconColor(props.row.marketing)"
                ></b-icon>
              </b-table-column>
              <b-table-column
                v-if="purchasedPdflify"
                v-slot="props"
                field="pdflify"
                sortable
                centered
                :label="$t('PDFlify')"
              >
                <b-icon
                  size="is-small"
                  :icon="getIcon(props.row.pdflify)"
                  :type="getIconColor(props.row.pdflify)"
                ></b-icon>
              </b-table-column>
              <b-table-column
                v-if="purchasedInventoryManager"
                v-slot="props"
                field="inventoryManager"
                sortable
                centered
                :label="$t('Inventory Manager')"
              >
                <b-icon
                  size="is-small"
                  :icon="getIcon(props.row.inventoryManager)"
                  :type="getIconColor(props.row.inventoryManager)"
                ></b-icon>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="customerAdministrator"
                sortable
                centered
                :label="$t('Administrator')"
              >
                <b-icon
                  size="is-small"
                  :icon="getIcon(props.row.customerAdministrator)"
                  :type="getIconColor(props.row.customerAdministrator)"
                ></b-icon>
              </b-table-column>
              <b-table-column
                v-slot="props"
                :visible="false"
                field="key"
                sortable
                label="Key"
                >{{ props.row.key }}</b-table-column
              >
              <template slot="footer">
                <th id="email" class="is-hidden-mobile" style="width: 40px">
                  <div class="th-wrap">{{ $t('Email') }}</div>
                </th>
                <th id="super-user" class="is-hidden-mobile">
                  <div class="th-wrap">{{ $t('Super User') }}</div>
                </th>
                <th
                  v-if="purchasedActivityMigrator"
                  id="activity-migrator"
                  class="is-hidden-mobile"
                >
                  <div class="th-wrap">{{ $t('Activity Migrator') }}</div>
                </th>
                <th
                  v-if="purchasedDataLoaders"
                  id="data-managers"
                  class="is-hidden-mobile"
                >
                  <div class="th-wrap">{{ $t('Data Managers') }}</div>
                </th>
                <th
                  v-if="purchasedIsochrone"
                  id="coverage-analytics"
                  class="is-hidden-mobile"
                >
                  <div class="th-wrap">{{ $t('Coverage Analytics') }}</div>
                </th>
                <th
                  v-if="purchasedOmw"
                  id="omw-viewer"
                  class="is-hidden-mobile"
                >
                  <div class="th-wrap">{{ $t('OMW Viewer') }}</div>
                </th>
                <th
                  v-if="purchasedOmwStats"
                  id="omw-stats"
                  class="is-hidden-mobile"
                >
                  <div class="th-wrap">{{ $t('OMW Stats') }}</div>
                </th>
                <th
                  v-if="purchasedOmw"
                  id="omw-viewer"
                  class="is-hidden-mobile"
                >
                  <div class="th-wrap">{{ $t('OMW Kill Switch') }}</div>
                </th>
                <th
                  v-if="purchasedFeedback"
                  id="customer-feedback"
                  class="is-hidden-mobile"
                >
                  <div class="th-wrap">{{ $t('Cust. Feedback') }}</div>
                </th>
                <th
                  v-if="purchasedRealTimeTravel"
                  id="real-time-travel"
                  class="is-hidden-mobile"
                >
                  <div class="th-wrap">{{ $t('Real-Time Travel') }}</div>
                </th>
                <th
                  v-if="purchasedMarketing"
                  id="omw-marketing"
                  class="is-hidden-mobile"
                >
                  <div class="th-wrap">{{ $t('OMW Marketing') }}</div>
                </th>
                <th
                  v-if="purchasedPdflify"
                  id="pdflify"
                  class="is-hidden-mobile"
                >
                  <div class="th-wrap">{{ $t('PDFlify') }}</div>
                </th>
                <th
                  v-if="purchasedInventoryManager"
                  id="inventory-manager"
                  class="is-hidden-mobile"
                >
                  <div class="th-wrap">{{ $t('Inventory Manager') }}</div>
                </th>
                <th id="administrator" class="is-hidden-mobile">
                  <div class="th-wrap">{{ $t('Administrator') }}</div>
                </th>
              </template>
            </b-table>
            <b-button type="is-primary" @click="getUsers">
              {{ $t('Refresh user list') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { defineComponent } from '@vue/composition-api';
import SelectedUser from './SelectedUser';

export default defineComponent({
  name: 'AdminScreen',
  searchOptions: {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 2,
    keys: ['key', 'email'],
  },
  data() {
    return {
      dataLoading: false,
      selectedRow: null,
      searchCriteria: null,
      searchResults: null,
      selectedUser: null,
    };
  },
  computed: {
    ...mapGetters('storeAuth', ['users']),
    // Used for switching between search results in component and full results in vuex store
    dataSet() {
      if (this.searchCriteria && this.searchCriteria.length > 1) {
        return this.searchResults;
      } else {
        return this.users;
      }
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    ...mapActions('storeAuth', ['fetchUsers']),
    async fetchData() {
      this.selectedUser = null;
      this.dataLoading = true;
      try {
        await this.fetchUsers();
      } catch (err) {
        console.error(err);
      } finally {
        this.dataLoading = false;
      }
    },
    showEditUser(user) {
      this.selectedUser = user;
      this.$buefy.modal.open({
        parent: this,
        component: SelectedUser,
        props: {
          id: this.selectedUser.key || false,
          email: this.selectedUser.email,
          dataLoaders: this.selectedUser.dataLoaders || false,
          omwLookup: this.selectedUser.omwLookup || false,
          omwStats: this.selectedUser.omwStats || false,
          omwKillSwitch: this.selectedUser.omwKillSwitch || false,
          feedback: this.selectedUser.feedback || false,
          superUser: this.selectedUser.superUser || false,
          isochrone: this.selectedUser.isochrone || false,
          activityMigrator: this.selectedUser.activityMigrator || false,
          realTimeTravel: this.selectedUser.realTimeTravel || false,
          marketing: this.selectedUser.marketing || false,
          pdflify: this.selectedUser.pdflify || false,
          inventoryManager: this.selectedUser.inventoryManager || false,
          customerAdministrator:
            this.selectedUser.customerAdministrator || false,
        },
        events: {
          'user-deleted': this.fetchData,
          'user-updated': this.fetchData,
        },
        hasModalCard: true,
      });
    },
    async getUsers() {
      this.dataLoading = true;
      try {
        await this.fetchUsers();
      } catch (err) {
        console.error(err);
      } finally {
        this.dataLoading = false;
      }
    },
    async search() {
      this.searchResults = await this.$search(
        this.searchCriteria,
        this.users,
        this.$options.searchOptions,
      );
    },
    getIcon(value) {
      if (value) {
        return 'check';
      } else {
        return 'times';
      }
    },
    getIconColor(value) {
      if (value) {
        return 'is-success';
      } else {
        return 'is-danger';
      }
    },
  },
});
</script>
